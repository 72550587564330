import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Text,
  Flex,
  Icon,
} from '@chakra-ui/react';
import GradientProgress from './GradientProgress';
import { ProfileStateContext } from '../../context/ProfileDataContext';
import { ICompDefault } from '../../utils/interfaces/TypesInterfaces';
import SectionWrapper from '../SectionWrapper';

import styles from './Skills.module.scss';
import { getSkillIcon } from './skillIconMap';

type ISkillsProps = ICompDefault;

const Skills = ({ id, className }: ISkillsProps) => {
  const { skillsData } = useContext(ProfileStateContext);
  const [displaySkills, setDisplaySkills] = useState(false);
  const minSkillNameWidth = ['80px', '150px'];

  useEffect(() => {
    if (skillsData && skillsData.visible && skillsData.skills.length > 0) {
      setDisplaySkills(true);
    }
  }, [skillsData]);

  if (skillsData && displaySkills) {
    return (
      <SectionWrapper id={id} headerText='Skills' className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
        <div className={`${styles.skillsContainer}`}>
          {skillsData.skills.map((skill: any) => (
            <Box
              width='full'
              key={skill.id}
              borderWidth='1px'
              borderRadius='lg'
              boxShadow='0px 0px 10px rgba(0, 0, 0, 0.1)'
              p={5}
              my={1}
            >
              <Flex
                direction={{ base: 'column', md: 'row' }}
                justify='space-between'
                align='center'
              >
                <Text
                  minWidth={minSkillNameWidth}
                  fontSize={['12px', '14px']}
                  fontWeight='900'
                  textAlign={{ base: 'center', md: 'left' }}
                  mb={{ base: 3, md: 0 }}
                >
                  <Icon as={getSkillIcon(skill.id)} aria-label={`${skill.name}`} mr={2} />
                  {skill.name}
                </Text>
                <Box position='relative' width='full' flex='1'>
                  <GradientProgress
                    value={skill.level ?? 0}
                    h='16px'
                    filledGradient='linear-gradient(270deg, #77CA97 2.96%, #018A57 96.87%)'
                    backgroundGradient='linear-gradient(270deg, #E7FBEE 0.19%, rgba(204, 204, 204, 0.35) 92.98%)'
                    borderRadius='16px'
                    hatched={false}
                  />
                  <Box position='absolute' top='0' left='0' right='0' px={2} height='16px'>
                    <Box
                      position='absolute'
                      top='50%'
                      left='0'
                      transform='translateY(-50%)'
                      w='full'
                      h='2px'
                      bgColor='gray.300'
                      zIndex='-1'
                    />
                    <Box
                      position='absolute'
                      top='50%'
                      left='25%'
                      transform='translateY(-50%)'
                      w='2px'
                      h='18px'
                      bgColor='gray.300'
                    />
                    <Box
                      position='absolute'
                      top='50%'
                      left='50%'
                      transform='translateY(-50%)'
                      w='2px'
                      h='14px'
                      bgColor='gray.300'
                    />
                    <Box
                      position='absolute'
                      top='50%'
                      left='75%'
                      transform='translateY(-50%)'
                      w='2px'
                      h='14px'
                      bgColor='gray.300'
                    />
                  </Box>
                  <Flex position='absolute' bottom='0' w='full' justify='space-between' pt={2}>
                    <Text fontSize={['10px', '12px']} />
                    <Text
                      fontSize={['10px', '12px']}
                      display={{ base: 'none', md: 'block' }}
                      position='absolute'
                      left='25%'
                      transform='translateX(-50%)'
                    >
                      Beginner
                    </Text>
                    <Text
                      fontSize={['10px', '12px']}
                      display={{ base: 'none', md: 'block' }}
                      position='absolute'
                      left='50%'
                      transform='translateX(-50%)'
                    >
                      Intermediate
                    </Text>
                    <Text
                      fontSize={['10px', '12px']}
                      display={{ base: 'none', md: 'block' }}
                      position='absolute'
                      left='75%'
                      transform='translateX(-50%)'
                    >
                      Advanced
                    </Text>
                    <Text
                      fontSize={['10px', '12px']}
                      display={{ base: 'none', md: 'block' }}
                      position='absolute'
                      right='3%'
                      mr='-12'
                      transform='translateX(-50%)'
                    >
                      Professional
                    </Text>
                    <Text
                      fontSize='10px'
                      display={{ base: 'block', md: 'none' }}
                      position='absolute'
                      left='25%'
                      transform='translateX(-50%)'
                    >
                      25%
                    </Text>
                    <Text
                      fontSize='10px'
                      display={{ base: 'block', md: 'none' }}
                      position='absolute'
                      left='50%'
                      transform='translateX(-50%)'
                    >
                      50%
                    </Text>
                    <Text
                      fontSize='10px'
                      display={{ base: 'block', md: 'none' }}
                      position='absolute'
                      left='75%'
                      transform='translateX(-50%)'
                    >
                      75%
                    </Text>
                    <Text
                      fontSize='10px'
                      display={{ base: 'block', md: 'none' }}
                      position='absolute'
                      right='0%'
                      mr={-2}
                      transform='translateX(-50%)'
                    >
                      100%
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          ))}
        </div>
      </SectionWrapper>
    );
  }

  return null;
};

export default Skills;
