import React from 'react';
import {
  Box,
  Progress,
  ProgressProps,
} from '@chakra-ui/react';

interface IGradientProgressProps extends ProgressProps {
  filledGradient: string,
  backgroundGradient: string,
  borderRadius: string | number,
  hatched: boolean,
}

const GradientProgress: React.FC<IGradientProgressProps> = ({
  filledGradient,
  backgroundGradient,
  borderRadius,
  hatched,
  value,
  ...props
}) => {
  const progressContainerRef = React.useRef<HTMLDivElement>(null);

  return (
    <Box
      position='relative'
      border={hatched ? '1px solid rgba(40, 40, 40, 0.6)' : ''}
      borderRadius={borderRadius}
      overflow='hidden'
      ref={progressContainerRef}
    >
      <Progress
        value={value}
        {...props} // eslint-disable-line react/jsx-props-no-spreading
        borderRadius={borderRadius}
        margin={hatched ? '-1px' : ''}
        sx={{
          // Background of the unfilled part
          background: backgroundGradient,
          backdropFilter: 'blur(133.764px)',
          '& > div': {
            // Filled part gradient
            backgroundImage: filledGradient,
            borderRadius,
            border: hatched && value && value > 0 ? '1px solid rgba(40, 40, 40, 0.4)' : '',
          },
        }}
      />
    </Box>
  );
};

export default GradientProgress;
