import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import Banner from './components/Banner';
import ReportAbuse from './components/ReportAbuse';
import W3LogoButton from './components/userlib/buttons/W3LogoButton';
import { NotificationStateProvider } from './context/NotificationContext';
import { ProfileStateProvider } from './context/ProfileDataContext';

import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider>
        <ProfileStateProvider>
          <NotificationStateProvider>
            <div className='PublicProfile' style={{ height: '100vh' }}>
              <Banner />

              <div className='PublicProfile-menu-item'>
                <W3LogoButton loggedIn={false} href='https://www.w3schools.com' className='PublicProfile-Logo' />
              </div>

              <Routes>
                <Route path='/report-abuse/' element={<ReportAbuse />} />
                <Route path='/:userId' element={<App />} />
                <Route
                  path='*'
                  element={
                    <main style={{ padding: '1rem' }}>
                      <p>There&apos;s nothing here!</p>
                    </main>
                  }
                />
              </Routes>
            </div>
          </NotificationStateProvider>
        </ProfileStateProvider>
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
